<template>
    <aside class="flex gap-1 flex-column align-items-start surface-100 py-4 px-2 mt-7 w-25rem">
        <div class="w-full flex flex-column gap-1 mt-5 p-2 select-none">
            <div class="w-full flex flex-column gap-1" v-for="(item, index) in menu">
                <div
                    class="flex gap-3 justify-content-between align-items-center border-round cursor-pointer hover:bg-blue-100 overflow-hidden"
                    @click="() => openMenuChildren(index)"
                >
                    <RouterLink
                        v-if="item.routerName"
                        :to="{ name: item.routerName }"
                        class="w-full flex flex-auto gap-3 align-items-center p-3"
                    >
                        <span :class="item.icon"></span>
                        <span>{{ item.label }}</span>
                    </RouterLink>
                    <div v-else class="flex flex-auto gap-3 align-items-center p-3">
                        <span :class="item.icon"></span>
                        <span>{{ item.label }}</span>
                    </div>
                    <span
                        class="pr-4 pi transition-duration-200"
                        :class="{ 'pi-angle-down': item.isOpen, 'pi-angle-right': !item.isOpen }"
                        v-if="item.children"
                    ></span>
                </div>
                <template v-if="item.children && item.isOpen">
                    <div
                        class="ml-5 flex gap-3 justify-content-between align-items-center border-round cursor-pointer hover:bg-blue-100 overflow-hidden"
                        v-for="children in item.children"
                    >
                        <RouterLink :to="{ name: children.routerName }" class="flex flex-auto gap-3 align-items-center p-3">
                            <span :class="children.icon"></span>
                            <span>{{ children.label }}</span>
                        </RouterLink>
                    </div>
                </template>
            </div>
        </div>
    </aside>
</template>

<script setup>
import { onMounted, ref } from "vue";

import { routerNames } from "@/app/providers/router";
import { PrimeIcons } from "primevue/api";
import { useRoute } from "vue-router";

const array = [
    {
        label: "Закупки",
        icon: "pi pi-shopping-bag",
        children: [
            {
                label: "Обложки",
                routerName: routerNames.ADMIN_PURCHASE_COVER,
                icon: PrimeIcons.IMAGE,
            },
            {
                label: "Привязка карт",
                routerName: routerNames.ADMIN_CART_BINDING,
                icon: PrimeIcons.CREDIT_CARD,
            },
        ],
    },
    {
        label: "Оплаты",
        icon: "pi pi-dollar",
        children: [
            {
                label: "Проверка оплат",
                routerName: routerNames.ADMIN_PAYMENTS,
                icon: PrimeIcons.HOURGLASS,
            },
            {
                label: "Оплаты на карту",
                routerName: routerNames.ADMIN_PAYMENTS_TO_CARD,
                icon: PrimeIcons.CREDIT_CARD,
            },
            {
                label: "Выгрузка оплаты",
                routerName: routerNames.ADMIN_EXPORT_PAYMENT,
                icon: PrimeIcons.FILE_EXPORT,
            },
        ],
    },
    {
        label: "Бонусная программа",
        icon: "pi pi-book",
        children: [
            {
                label: "Настройки",
                icon: "pi pi-cog",
                routerName: routerNames.ADMIN_BONUS_SETTINGS,
            },
            {
                label: "Исключения",
                icon: PrimeIcons.USER_MINUS,
                routerName: routerNames.ADMIN_BONUS_EXCEPTION,
            },
            {
                label: "Ручное начисление",
                icon: PrimeIcons.WAVE_PULSE,
                routerName: routerNames.ADMIN_BONUS_UPLOAD,
            },
        ],
    },
];

const route = useRoute();

const menu = ref([]);

function openMenuChildren(index) {
    menu.value[index].isOpen = !menu.value[index].isOpen;
}

onMounted(() => {
    for (const row of array) {
        if (!row.children) continue;

        for (const rowChildren of row.children) {
            if (rowChildren.routerName === route.name) {
                row.isOpen = true;
            }
        }
    }
    menu.value = array;
});
</script>

<style lang="scss" scoped>
@import "primeflex/primeflex.scss";
.router-link-active {
    @include styleclass("bg-blue-100 text-blue-500");
}
</style>
