export const routerNames = {
    MAIN: "Main",

    SIGNIN: "Signin",
    SIGNUP: "Signup",
    FORGOT_PASSWORD: "ForgotPassword",
    RESET_PASSWORD: "ResetPassword",

    DELIVERY_AND_PAYMENT: "DeliveryAndPayment",
    CONTACTS: "Contacts",
    RETURN_GOODS: "ReturnGoods",
    TERMS: "Terms",
    POLICY: "Policy",
    ACCEPT_POLICY: "AcceptPolicy",

    ORDER: "Order",

    PAYMENT_RESULT: "PaymentResult",

    PERSONAL_ACTIVATION: "PersonalActivation",
    PERSONAL_HOME: "PersonalHome",
    PERSONAL_ORDERS_UNPAID: "PersonalUnpaidOrders",
    PERSONAL_ORDERS_PAID: "PersonalPaidOrders",
    PERSONAL_BONUS: "PersonalBonus",
    PERSONAL_DELIVERY: "PersonalDelivery",
    PERSONAL_PROFILE: "PersonalProfile",

    ADMIN_PURCHASE_COVER: "AdminPurchaseCover",
    ADMIN_CART_BINDING: "AdminCartBinding",
    ADMIN_BONUS: "AdminBonus",
    ADMIN_BONUS_SETTINGS: "AdminBonusSettings",
    ADMIN_BONUS_EXCEPTION: "AdminBonusException",
    ADMIN_BONUS_UPLOAD: "AdminBonusUpload",
    ADMIN_USER: "AdminUser",
    ADMIN_PAYMENTS: "AdminPayments",
    ADMIN_PAYMENTS_TO_CARD: "AdminCheckReceipt",
    ADMIN_EXPORT_PAYMENT: "AdminExportPayment",
};
