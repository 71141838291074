import { computed } from "vue";
import { storeToRefs } from "pinia";

import { useSessionStore } from "@/entities/Session";
import { useUserStore } from "@/entities/User";
import { routerNames } from "./routerNames";
import { USER_ROLE } from "@/shared/lib/types";

export function requiredAuth(to, from, next) {
    const sessionStore = useSessionStore();

    if (sessionStore.isAuth) {
        return next();
    }

    next({
        name: routerNames.SIGNIN,
    });
}
export function notRequiredAuth(to, from, next) {
    const sessionStore = useSessionStore();

    if (sessionStore.isAuth) {
        return next({
            name: routerNames.PERSONAL_HOME,
        });
    }

    next();
}
export function checkRole(to, from, next) {
    const userStore = useUserStore();
    const { userData } = storeToRefs(userStore);

    const {
        meta: { role },
    } = to;

    if (role === userData.value.role) {
        return next();
    }

    if (userData.value.role === USER_ROLE.ADMIN) {
        console.log("I am Admin!");
        return next({ name: routerNames.ADMIN_PURCHASE_COVER });
    }
    if (userData.value.role === USER_ROLE.CLIENT) {
        console.log("I am Client!");
        return next({ name: routerNames.PERSONAL_HOME });
    }

    alert("Неизвестная роль");
}
export async function checkConfirmAccount(to, from, next) {
    const userStore = useUserStore();
    const { userData } = storeToRefs(userStore);

    const accountActivate = computed(() => userData.value.isConfirmedAccount);

    console.log("accountActivate", accountActivate.value);

    if (to.name === routerNames.PERSONAL_ACTIVATION) {
        if (accountActivate.value) return next({ name: routerNames.PERSONAL_HOME });
        return next();
    }
    if (accountActivate.value) return next();

    next({ name: routerNames.PERSONAL_ACTIVATION });
}
export function setTitle(to, from, next) {
    const title = to.meta?.title;
    if (title) document.title = title;

    next();
}
